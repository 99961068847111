export default async  function verifyRazorpayPayment(paymentObj , jsonHeader , URLS){

      const {
        razorpay_order_id: orderId,
        razorpay_signature: signatureId,
        razorpay_payment_id: paymentId,
      } = paymentObj;
      const requestObj = {
        headers: jsonHeader,
        body: JSON.stringify({
          orderId,
          signatureId,
          paymentId,
        }),
        method: "POST",
        redirect: "follow",
      };
      const paymentVerificationUrl = `${URLS.proxyUrl}${URLS.api_base_url}api/forms/PaymentVerification`;
      const responseObj = await fetch(paymentVerificationUrl, requestObj);
      const verificationRespObj = await responseObj.json();
      return verificationRespObj.data;
}