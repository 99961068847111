<template>
  <form v-on:submit.prevent="licenseRenewFormEditSubmit">
    <div v-if="newLicenseList" class="form-group row">
      <label class="col-sm-2 col-form-label">New License</label>
      <div class="">
        <select
          class="form-control"
          :disabled="disabledFields.addLicense"
          @change="getNewLicenseExtendedDate"
          v-model="angelFormState.data.inputFields.userDetails.newLicenseSelected"
        >
          <option
            v-for="(licence, index) in userDetails.data.licenceArr"
            v-bind:value="licence"
            v-bind:key="index"
          >
            {{ licence.license_name }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="
        angelFormState.data.inputFields.userDetails.newLicenseSelected != null &&
        angelFormState.data.inputFields.userDetails.newLicenseSelected != 'null'
      "
      class="form-group row"
    >
      <div class="form-group row dynamicField">
        <label class="col-sm-2 col-form-label">Amount</label>

        <input
          type="text"
          class="form-control"
          disabled
          :value="newLicenseAmount"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Expiry</label>
      <div class="">
        <input
          type="date"
          disabled
          class="form-control"
          :value="userDetails.data.ExpiryDate"
        />
      </div>
    </div>
    <div
      v-if="
        angelFormState.data.inputFields.userDetails.newLicenseSelected != null &&
        angelFormState.data.inputFields.userDetails.newLicenseSelected != 'null' &&
        angelFormState.data.inputFields.NewLicenseExtendedDate != null &&
        angelFormState.data.inputFields.NewLicenseExtendedDate != 'null'
      "
      class="form-group row"
    >
      <label for="inputEmail3" class="col-sm-2 col-form-label"
        >Expiry After Purchasing License</label
      >
      <div class="">
        <input
          type="date"
          :value="
            new Date(angelFormState.data.inputFields.NewLicenseExtendedDate).toLocaleDateString(
              'en-CA'
            )
          "
          disabled
          class="form-control"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-10">
        <button
          type="submit"
          :disabled="isSubmitBtnDisabled"
          class="fetch_button sign_button"
          :class="{ disabled: isSubmitBtnDisabled }"
        >
          Submit
        </button>
        <!-- <button type="reset" class="fetch_button reset_button">Reset</button> -->
      </div>
    </div>
  </form>
</template>

<script>
import { userDetails } from "../state/userDetails.js";
import { validationRegex } from "../state/validationRegex.js";
import { dynamicFormData } from "../state/dynamicFormData.js";
import fetchPaymentObj from "../hooks/fetchPaymentObj.js";
import verifyRazorpayPayment from "../hooks/verifyRazorpayPayment.js";
import { URLS } from "../state/URLS.js";
import { angelFormState } from "../state/angelFormState.js";


export default {
  props: ["AUTH_DETAILS"],
  async mounted() {
    await this.displayUserDetails();
            setTimeout(this.getNewLicenseExtendedDate , 0);

               
  },
  data() {
    return {
      dynamicFormData,
      userDetails,
      URLS,
      newData: {},
      inputFields: {
        newLicenseAmount: 0,
        name: "",
        password: "",
        showChangeBroker: false,
        NewPassword: "",
        ConfirmPassword: "",
        NewLicenseExtendedDate: "",
        userDetails: {
          email: "",
          phone: "",
          broker: {},
          license: {},
          licenceArr: [],
          NumberOfLicense: 0,
          amount: 0,
          showChangedPassword: false,
          SubscriptionExpiryDate: "",
          newLicenseSelected: null,
          existingLicense: [],
          stags: [],
        },
        email: "",
        phone: "",
        isShowing: false,
        isLoginByOtp: false,
        license: "PaperTrading",
        amount: 0,
        otp: null,
      },
      angelFormState,
      validationRegex,
      disabledFields: {
        changepassword: false,
        changeBroker: false,
        addLicense: false,
      },
    };
  },
  methods: {
    async licenseRenewFormEditSubmit() {
      this.newData = {};
      const isNewChangesConfirmed = await this.$swal({
        title: "Confirm",
        text: "Are you sure you want to Update the data?",
        icon: "warning",
        backdrop: true,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Update",
      });
      if (!isNewChangesConfirmed.isConfirmed) {
        return;
      }
      this.newData = {
        Username: this.userDetails.data.MyLogin,
      };

      if (this.isEmailValid) {
        this.newData.Email = this.angelFormState.data.inputFields.userDetails.email;
      }

      if (this.isPhoneValid) {
        this.newData.Mobile = this.angelFormState.data.inputFields.userDetails.phone;
      }

      const isPasswordChange = this.angelFormState.data.inputFields.showChangedPassword;
      // const isPasswordChange = true;

      if (isPasswordChange) {
        //
        if (this.isPasswordValid) {
          this.newData.Password = this.angelFormState.data.inputFields.NewPassword;
          // newData.Password = "123";

          //     const isPasswordChanged = await this.changePassword();
          //     if (isPasswordChanged) {
          //       this.inputFields.showChangedPassword = false;
          //       this.disabledFields.changePassword = true;
          //     }
          //   } else {
          //     alert("Password not Valid");
        }
      }
      if (this.angelFormState.data.inputFields.showChangeBroker) {
        this.newData.AdminBrokerID =
          this.angelFormState.data.inputFields.userDetails.broker.BrokerValue;
        this.newData.BrokerBaseURI =
          this.angelFormState.data.inputFields.userDetails.broker.BrokerUrl;
        // newData.AdminBrokerID = this.inputFields.userDetails.broker.BrokerName
        //   const isBrokerChanged = await this.changeBroker();
        //   if (isBrokerChanged) {
        //     this.inputFields.showChangeBroker = false;
        //     this.disabledFields.changeBroker = true;
        //   }
      }
      let isNewLicenseSelected = false;
      const newLicenseSelected =
        this.angelFormState.data.inputFields.userDetails.newLicenseSelected;

      if (newLicenseSelected != "null" && newLicenseSelected != null) {
        isNewLicenseSelected = true;
        await this.addNewLicense();
        return;
      }

      if (isNewChangesConfirmed.isConfirmed && !isNewLicenseSelected) {
        const isDataModifiedSuccessfully = await this.modifyData(this.newData);
        if (isDataModifiedSuccessfully) {
          this.$swal({
            title: "Success",
            text: "Data Updated Successfully",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });
        }
      }
      // const
    },
    async modifyData(newData) {
      const modifyUrl = `${this.URLS.proxyUrl}${this.URLS.api_base_url}api/user/modify`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append(
        "Authorization",
        `Bearer ${this.userDetails.data.Token}`
      );
      const requestObj = {
        method: "POST",
        body: JSON.stringify(newData),
        headers: jsonHeader,
      };
      const newDataModifyResp = await fetch(modifyUrl, requestObj);
      const modifyDataRespJson = await newDataModifyResp.json();

      return modifyDataRespJson.code == 200;
    },
    verifyRazorpayPayment,
    async addNewLicense() {
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append(
        "Authorization",
        `Bearer ${this.userDetails.data.Token}`
      );
      const paymentDetailsObj = {
        name: this.userDetails.data.Name,
        email: this.userDetails.data.Email,
        phone: this.userDetails.data.Mobile,
        amount: this.newLicenseAmount,
        username: this.userDetails.data.Username,
      };
      // const paymentRespUrl = `${this.URLS.proxyUrl}${this.URLS.api_base_url}/api/forms/CreateRazorPayOrder`;
      const paymentObj = await this.fetchPaymentObj(
        jsonHeader,
        paymentDetailsObj,
        this.URLS
      );
      paymentObj.handler = this.paymentHandler;
      const rzp1 = new Razorpay(paymentObj);
      rzp1.open();
    },
    async updateRecordsAngel(paymentId) {
      const newLicenseSelected =
        this.angelFormState.data.inputFields.userDetails.newLicenseSelected;
       
      let planName = "";
       let validity = "";
      if (newLicenseSelected.number_of_license == 1) {
        planName = "Explorer";
        validity = 1;
      } else if(newLicenseSelected.number_of_license == 12) {
        planName = "Determined";
        validity = 4;
      }
      // planName = newLicenseSelected.number_of_license == 1
      debugger
      const payload = {
        partnerUserID: "saurabh@algoji.com",
        ClientCode: this.userDetails.data._Name,
        subscriptionDate: (new Date()).toISOString(),
        planName: planName,
        validity: validity,
        expiryDate: new Date(
          this.angelFormState.data.inputFields.NewLicenseExtendedDate
        ).toISOString(),
        subscriptionAmount: newLicenseSelected.amount,
        paymentID: paymentId,
        productName: "APIBridge",
      };
      // console.log({payload})
      // return ;
      let myHeaders = new Headers();
      myHeaders.append("X-ClientLocalIP", "192.168.0.0");
      myHeaders.append("X-ClientPublicIP", "268.98.11.223");
      myHeaders.append("X-MACaddress", "00:25:96:FF:FE:12:34:56");
      myHeaders.append("X-UserType", "Partner");
      myHeaders.append("content-type", "application/json");
      myHeaders.append("X-Password", "@lgoji455");

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: "follow",
      };
      fetch("https://smartstoreapi.angelbroking.com/rest/secure/angelbroking/playstore/go/partner/v1/pushPartnerData", requestOptions)
    },
    async paymentHandler(...params) {
      const paymentRespObj = params[0];
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append(
        "Authorization",
        `Bearer ${this.userDetails.data.Token}`
      );
      const isPaymentVerified = await this.verifyRazorpayPayment(
        paymentRespObj,
        jsonHeader,
        this.URLS
      );
      if (isPaymentVerified) {
        // await this.createLicenses();
        // this.disabledFields.addLicense = true;
          console.log({paymentRespObj})
          this.updateRecordsAngel(paymentRespObj.razorpay_payment_id)
               this.newData.ModuleID =
          this.angelFormState.data.inputFields.userDetails.newLicenseSelected.module_type;
        // this.newData.ModuleID = 1;
        this.newData.NumberOfLicense =
          this.angelFormState.data.inputFields.userDetails.newLicenseSelected.number_of_license;
        const isDataModified = await this.modifyData(this.newData);
        await this.$swal({
          title: "Success",
          text: "Payment Done Successfully",
          icon: "success",
          backdrop: true,
          allowOutsideClick: false,
        });

   
        if (isDataModified) {
          this.$swal({
            title: "Success",
            text: "Data Updated Successfully",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

          this.angelFormState.data.inputFields.userDetails.newLicenseSelected = "null";
        }
      }
    },

    async changePassword() {
      const changePasswordUrlStr = `${this.URLS.admin_base_url}/Admin/resetUserPassword`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const requestObj = {
        method: "PUT",
        headers: jsonHeader,
        redirect: "follow",
        body: JSON.stringify({
          Username: this.userDetails.data.Username,
          NewPassword: this.angelFormState.data.inputFields.NewPassword,
          ConfirmPassword: this.angelFormState.data.inputFields.ConfirmPassword,
        }),
      };
      const changePasswordResp = await fetch(changePasswordUrlStr, requestObj);
      const changePasswordJSON = await changePasswordResp.json();

      const isPasswordChangeSuccessfull = changePasswordJSON.code == 200;
      if (isPasswordChangeSuccessfull) {
        alert("Password Changed Successfull");
      }
      return isPasswordChangeSuccessfull;
    },

    async changeBroker() {
      const changeBrokerUrl = `${this.URLS.admin_base_url}/Admin/ChangeUserBroker`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);

      const requestObj = {
        method: "PUT",
        headers: jsonHeader,
        body: JSON.stringify({
          UserID: this.userDetails.data.ID,
          AdminBrokerID: this.angelFormState.data.inputFields.userDetails.broker.BrokerValue,
        }),
      };
      const changeBrokerResp = await fetch(changeBrokerUrl, requestObj);
      const changeBrokerRespJson = await changeBrokerResp.json();

      const isBrokerChanged = changeBrokerRespJson.code == 200;
      if (isBrokerChanged) {
        alert("Broker Changed Successful");
      }

      return isBrokerChanged;
    },
    fetchPaymentObj,
    async createLicenses() {
      const createLicenseApiUrl = `${this.proxyUrl}${this.URLS.admin_base_url}/Admin/assignUserLicense`;
      const jsonHeaders = this.getJsonHeaders();
      jsonHeaders.add("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const reqBody = JSON.stringify({
        UserID: this.AUTH_DETAILS.Admin_id,
        ModuleID: this.angelFormState.data.inputFields.userDetails.newLicenseSelected.module_type,
        NumberOfLicense:
          this.angelFormState.data.inputFields.userDetails.newLicenseSelected.number_of_license,
      });

      const reqObj = {
        method: "POST",
        body: reqBody,
        headers: jsonHeaders,
      };
      const resp = await fetch(createLicenseApiUrl, reqObj);
      const respJson = await resp.json();

      return respJson;
    },
    getJsonHeaders() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    async getAllLicence() {
      this.userDetails.data.licenceArr = [];
      const getAllLicenceUrl = `${this.URLS.proxyUrl}${this.URLS.api_base_url}api/forms/FetchLicenseList`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      // console.log(this.AUTH_DETAILS);

      const reqObj = {
        headers: jsonHeader,
        method: "POST",
        redirect: "follow",
      };
      const getLicenceResp = await fetch(getAllLicenceUrl, reqObj);
      const getLicenceJson = await getLicenceResp.json();

      const licenceArr = getLicenceJson.data;

      this.userDetails.data.licenceArr = licenceArr;
      if (this.userDetails.data?.licenceArr?.length) {
        this.angelFormState.data.inputFields.userDetails.license =
          this.userDetails.data.licenceArr[0];
      }
    },

    async fetchBrokerList() {
      // const brokerListUrl = `${this.proxyUrl}${this.api_base_url}api/forms/FetchLicenseList`;
      const brokerListUrl = `${this.URLS.api_base_url}api/forms/FetchBrokerList`;

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${this.userDetails.data.Token}`);

      const requestObj = {
        method: "GET",
        headers,
      };
      const resp = await fetch(brokerListUrl, requestObj);
      const brokerListDataObj = await resp.json();
      this.userDetails.data.BrokerList = brokerListDataObj.data;
    },
    async displayUserDetails() {
      this.isUserDetailsDisplayed = true;

      this.angelFormState.data.inputFields.userDetails.email = this.userDetails.data.Email;
      this.angelFormState.data.inputFields.userDetails.phone = this.userDetails.data.Mobile;
      this.angelFormState.data.inputFields.userDetails.NumberOfLicense =
        this.userDetails.data.NumberOfLicense;
      this.angelFormState.data.inputFields.userDetails.SubscriptionExpiryDate =
        this.userDetails.data.SubscriptionExpiryDate;
      this.angelFormState.data.inputFields.userDetails.license.ModuleID =
        this.userDetails.data.ModuleID;

      // await this.fetchBrokerList();

      if (this.userDetails.data.BrokerList?.length) {
        if (this.userDetails.data.BrokerList.length > 1) {
          this.angelFormState.data.inputFields.userDetails.broker =
            this.userDetails.data.BrokerList.filter(
              (broker) => broker.BrokerValue == this.userDetails.data.BrokerID
            )[0];

          // console.log(this.userDetails.data.Broker.toLowerCase().trim());
        } else {
          this.angelFormState.data.inputFields.userDetails.broker =
            this.userDetails.data.BrokerList[0];
        }
      }
      // if (this.userDetails.data.licenceArr?.length) {
      //   this.userDetails.data.currentLicense = this.userDetails.data.licenceArr.filter(
      //     (license) => this.userDetails.data.ModuleID == license.module_type
      //   )[0];
      // }
      // this.getUserStags();
      // this.inputFields.userDetails.broker =
      // this.inputFields.userDetails.expiraryDate =
      // this.inputFields.userDetails.amount =
    },
    newLicenseSelected() {
      this.getNewLicenseExtendedDate();
    },
    getNewLicenseExtendedDate() {
      debugger
      const newLicenseSelected =
        this.angelFormState.data.inputFields.userDetails.newLicenseSelected;
      if (newLicenseSelected != null && newLicenseSelected != "null") {
        let startDate = new Date();

        debugger
        if (new Date(this.userDetails.data.ExpiryDate).getTime() > startDate.getTime()) startDate = new Date(this.userDetails.data.ExpiryDate) 
        debugger
        const numberOfNewLicense = newLicenseSelected.number_of_license;
        startDate.setDate(startDate.getDate() + 30 * numberOfNewLicense);

        // const lastLicenseDate = new Date(this.userDetails.data.ExpiryDate);
        // lastLicenseDate.getTime() > startDate.getTime()
        //   ? (startDate = lastLicenseDate)
        //   : "";
        // debugger
        // startDate.setDate(startDate.getDate() + 30 * numberOfNewLicense);
        this.angelFormState.data.inputFields.NewLicenseExtendedDate = startDate;
        return startDate.toLocaleDateString("en-CA");
      } else {
        this.angelFormState.data.inputFields.NewLicenseExtendedDate = null;

        return this.inputFields.NewLicenseExtendedDate;
      }
    },
  },
  computed: {
    newLicenseList() {
      if (this.userDetails.data.licenceArr?.length) {
        return this.userDetails.data.licenceArr.filter((license) => {
          return license.module_type != 1;
        });
      }
      return [];
    },
    isPasswordValid() {
      if (
        this.angelFormState.data.inputFields.NewPassword != "" &&
        this.angelFormState.data.inputFields.ConfirmPassword != "" &&
        this.angelFormState.data.inputFields.ConfirmPassword == this.angelFormState.data.inputFields.NewPassword
      ) {
        return true;
      }
      return false;
    },
    newLicenseAmount() {
      // const currentModule = this.inputFields.userDetails.newLicenseSelected.module_type;
      const newLicenseSelected =
        this.angelFormState.data.inputFields.userDetails.newLicenseSelected;

      // }
      if (newLicenseSelected != null) {
        return newLicenseSelected.amount ? newLicenseSelected.amount : 0;
      }
      return 0;
    },
    isEmailValid() {
      if (this.angelFormState.data.inputFields.userDetails.email.trim()) {
        return this.validationRegex.email.test(
          this.angelFormState.data.inputFields.userDetails.email
        );
      }
      return false;
    },
    isSubmitBtnDisabled() {
      const isSubmitBtnDisabled = false;

      if (this.angelFormState.data.inputFields.showChangedPassword) {
        if (!this.isPasswordValid) {
          return true;
        }
      }

      if (!this.isEmailValid) {
        return true;
      }
      if (!this.isPhoneValid) {
        return true;
      }
      if (this.angelFormState.data.inputFields.showChangeBroker) {
        if (
          this.angelFormState.data.inputFields.userDetails.broker == undefined ||
          !this.angelFormState.data.inputFields.userDetails.broker ||
          this.angelFormState.data.inputFields.userDetails.broker == null
        ) {
          return true;
        }
      }

      return isSubmitBtnDisabled;
    },
    isPhoneValid() {
      if (this.angelFormState.data.inputFields.userDetails.phone.trim()) {
        return this.validationRegex.mobile.test(
          this.angelFormState.data.inputFields.userDetails.phone
        );
      }
      return false;
    },
  },
  watch:{
    "angelFormState.data.inputFields.userDetails.newLicenseSelected":{
      handler(){
        debugger
        setTimeout(this.getNewLicenseExtendedDate , 0);
      },
      deep:true
    }
  }
};
</script>

<style scoped>
button.disabled {
  cursor: not-allowed !important;
}
.form-group label {
  margin: 10px;
}
.row {
  flex-wrap: wrap;
  align-items: center;
  justify-items: flex-start;
}
form {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
select {
  max-width: 14rem;
}

@media (max-width: 700px) {
  select {
    max-width: 16rem;
  }
}
.form-control {
  min-width: 14rem;
  margin: auto 10px;
}
</style>

<style >
.userDetailsEditContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
}
.form-group label {
  text-align: left;
}
</style>