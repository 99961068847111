var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.licenseRenewFormEditSubmit($event)}}},[(_vm.newLicenseList)?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("New License")]),_c('div',{},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.angelFormState.data.inputFields.userDetails.newLicenseSelected),expression:"angelFormState.data.inputFields.userDetails.newLicenseSelected"}],staticClass:"form-control",attrs:{"disabled":_vm.disabledFields.addLicense},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.angelFormState.data.inputFields.userDetails, "newLicenseSelected", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.getNewLicenseExtendedDate]}},_vm._l((_vm.userDetails.data.licenceArr),function(licence,index){return _c('option',{key:index,domProps:{"value":licence}},[_vm._v(" "+_vm._s(licence.license_name)+" ")])}),0)])]):_vm._e(),(
      _vm.angelFormState.data.inputFields.userDetails.newLicenseSelected != null &&
      _vm.angelFormState.data.inputFields.userDetails.newLicenseSelected != 'null'
    )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"form-group row dynamicField"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Amount")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.newLicenseAmount}})])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputEmail3"}},[_vm._v("Expiry")]),_c('div',{},[_c('input',{staticClass:"form-control",attrs:{"type":"date","disabled":""},domProps:{"value":_vm.userDetails.data.ExpiryDate}})])]),(
      _vm.angelFormState.data.inputFields.userDetails.newLicenseSelected != null &&
      _vm.angelFormState.data.inputFields.userDetails.newLicenseSelected != 'null' &&
      _vm.angelFormState.data.inputFields.NewLicenseExtendedDate != null &&
      _vm.angelFormState.data.inputFields.NewLicenseExtendedDate != 'null'
    )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputEmail3"}},[_vm._v("Expiry After Purchasing License")]),_c('div',{},[_c('input',{staticClass:"form-control",attrs:{"type":"date","disabled":""},domProps:{"value":new Date(_vm.angelFormState.data.inputFields.NewLicenseExtendedDate).toLocaleDateString(
            'en-CA'
          )}})])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-sm-10"},[_c('button',{staticClass:"fetch_button sign_button",class:{ disabled: _vm.isSubmitBtnDisabled },attrs:{"type":"submit","disabled":_vm.isSubmitBtnDisabled}},[_vm._v(" Submit ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }