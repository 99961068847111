export default async function fetchPaymentObj(jsonHeader , paymentDetailsObj , URLS) {
    const payload = JSON.stringify({
      name: paymentDetailsObj.name,
      email: paymentDetailsObj.email,
      phone: paymentDetailsObj.phone,
      amount: paymentDetailsObj.amount * 100,
      username: paymentDetailsObj.username, 
      adminApiUserName: "",
    });
    const requestObj = {
      method: "POST",
      headers: jsonHeader,
      body: payload,
      redirect: "follow",
    };
    
    const paymentRespUrl = `${URLS.proxyUrl}${URLS.api_base_url}/api/forms/CreateRazorPayOrder`;
    const paymentObjResp = await fetch(paymentRespUrl, requestObj);
    const paymentObj = await paymentObjResp.json();
    return paymentObj.data;
  }